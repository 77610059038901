import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { LoginComponent } from "../login/login.component";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
//import { SharedModule } from "@progress/kendo-angular-dialog";
const routes = [
  {
    path: "auth/login",
    component: LoginComponent,
  },
];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    ButtonsModule,
    CommonModule,
    CommonModule,
    FormsModule,
    FormsModule,
    InputsModule,
    LabelModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
  //  SharedModule,
  ],
  exports: [LoginComponent],
})
export class LoginModule {}
