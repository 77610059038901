import { DialogModule } from '@progress/kendo-angular-dialog';
import { ConfirmComponent } from './layout/confirm/confirm.component';
import { LoaderService } from './services/loader.service';
import { ErrorMessageService } from './services/error-message.service';
import { ListItemsService, ReportsService, UsersService } from './swagger-services/api.client.generated';
import { ErrorMessageComponent } from './layout/error-message/error-message.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HttpErrorInterceptor } from './interceptors/error-handler.interceptor';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { AppRoutingModule } from './app-routing.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ConfirmService } from './services/confirm.service';
import { LoginModule } from './pages/login/login.module';
import { AuthGuard } from './services/auth-guard';
import { AuthInterceptor } from './services/auth.interceptor';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HasRoleDirective } from './directives/has-role.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { API_BASE_URL, BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { ICON_SETTINGS } from "@progress/kendo-angular-icons";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    ErrorMessageComponent,
    ConfirmComponent,
    HasRoleDirective,
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    IndicatorsModule,
    ButtonsModule,
    DialogModule,
    LoginModule,
    ReactiveFormsModule,
    // LocalStorageModule.forRoot({
    //   prefix: 'mint-app',
    //   storageType: 'localStorage',
    // }),
    CommonModule,
    BrowserModule,
    FontAwesomeModule,
  ],
  providers: [
    ErrorMessageService,
    LoaderService,
    AuthService,
    ConfirmService,
    ListItemsService,
    AuthService,
    AuthGuard,
    ReportsService,
    UsersService,
    {
      provide: API_BASE_URL,
      useValue: environment.apiRoot
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
      deps: [API_BASE_URL]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: ICON_SETTINGS, useValue: { type: 'font' } }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
