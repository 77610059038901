<header>
  <nav
    class="
      navbar navbar-expand-sm navbar-toggleable-sm navbar-light
      bg-white
      box-shadow
      h-100
    "
  >
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/']"
        ><img src="assets/MINT_logo.png"
      /></a>
      <div class="user-wrapper" *ngIf="authService.isLoggedIn">
        <div class="right-part ml-3">
          <div class="userimg">
            <span
              >{{ authService.accessToken.firstName.charAt(0) }}.{{
                authService.accessToken.lastName.charAt(0)
              }}.</span
            >
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
