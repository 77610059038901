import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./services/auth-guard";

const appRoutes: Routes = [
  {
    path: "admin",
    loadChildren: () =>
      import("./pages/admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: "",
    redirectTo: "projects",
    pathMatch: "full",
  },
  {
    path: "products",
    loadChildren: () =>
      import("./pages/products/products.module").then((m) => m.ProductsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "projects",
    loadChildren: () =>
      import("./pages/projects/projects.module").then((m) => m.ProjectsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "suppliers",
    loadChildren: () =>
      import("./pages/suppliers/suppliers.module").then(
        (m) => m.SuppliersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "manufacturers",
    loadChildren: () =>
      import("./pages/manufacturers/manufacturers.module").then(
        (m) => m.ManufacturersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "accessories",
    loadChildren: () =>
      import("./pages/accessories/accessories.module").then(
        (m) => m.AccessoriesModule
      ),
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
