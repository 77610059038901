import { Observable, Subject } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConfirmService implements OnDestroy {
  public openDialog = new Subject<string>();
  private dialogConfirmed!: Subject<boolean>;

  ngOnDestroy(): void {
    if (this.dialogConfirmed) {
      this.dialogConfirmed.complete();
    }

    this.openDialog.complete();
  }

  public setIsConfirmed(isConfirmed: boolean): void {
    this.dialogConfirmed.next(isConfirmed);
    this.dialogConfirmed.complete();
  }

  public showConfirmation(message: string | null = null): Observable<boolean> {
    this.dialogConfirmed = new Subject<boolean>();
    this.openDialog.next(message!);
    return this.dialogConfirmed;
  }
}
