import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from "@angular/common/http";

import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { AuthInfoModel } from "../models/security/auth-Info-model";
// import { AuthInfoModel } from '../models/security/auth-Info-model';
// import { UserRoles } from '../models/user-roles';

//import { RequestOptionsArgs, RequestOptions, Headers } from '@angular/http';

//TODO: implement token refresh at some point --

@Injectable()
export class AuthService {
  public user = JSON.parse(localStorage.getItem("auth-token") as string);
  public user$: BehaviorSubject<any> = new BehaviorSubject<any>(this.user);

  isLoginError!: boolean;

  loginStatusChange: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient, private router: Router) {}

  // public getUser() {
  //   if (!this.user) {
  //     this.loaderService.loadingActive$.next(true);
  //     this.loginService
  //       .getUser()
  //       .pipe(
  //         finalize(() => {
  //           this.loaderService.loadingActive$.next(false);
  //         })
  //       )
  //       .subscribe((user) => {
  //         localStorage.setItem('user', JSON.stringify(user));
  //         this.user$.next(user);
  //       });
  //     }
  //   }

  login(username: string, password: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const params = new HttpParams()
      .append("email", username)
      .append("password", password);

    let requestBody = params.toString();

    // this call will give 401 (access denied HTTP status code) if login unsuccessful)
    return this.http
      .post<boolean>("api/auth/login", requestBody, httpOptions)
      .pipe<boolean>(
        map((value: any) => {
          let token = value as AuthInfoModel;
          this.parseToken(token);
          return true;
        })
      );
  }

  logout(): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    const params = new HttpParams();
    let requestBody = params.toString();
    return this.http
      .post<boolean>("api/auth/logout", requestBody, httpOptions)
      .pipe<boolean>(
        map((value: any) => {
          this.removeToken();
          this.loginStatusChange.next(false);
          this.user$.next(null);
          return true;
        })
      );
  }

  parseToken(token: AuthInfoModel): void {
    const now = new Date();
    token.expiration_date = new Date(
      now.getTime() + token.expires_in * 60 * 1000
    )
      .getTime()
      .toString();

    this.storeToken(token);
    this.loginStatusChange.next(true);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  storeToken(token: AuthInfoModel): void {
    localStorage.setItem("auth-token", JSON.stringify(token));
    this.user$.next(token);
  }

  get accessToken(): AuthInfoModel {
    let tokenJson = localStorage.getItem("auth-token");
    if (tokenJson) {
      let token = <AuthInfoModel>JSON.parse(tokenJson);
      return token;
    }
    return {
      access_token: "",
      roles: [],
      firstName: "",
      lastName: "",
    } as unknown as AuthInfoModel;
  }

  public isInRole(role: string): boolean {
    return (
      this.accessToken &&
      this.accessToken.roles &&
      this.accessToken.roles.indexOf(role) >= 0
    );
  }

  removeToken(): void {
    localStorage.removeItem("auth-token");
  }

  get isLoggedIn(): boolean {
    if (typeof window !== "undefined") {
      // hack for server side rendering, server side dose not have --
      let tokenJson = localStorage.getItem("auth-token");
      if (!tokenJson) return false;

      let token = <AuthInfoModel>JSON.parse(tokenJson);

      return new Date().getTime() < +token.expiration_date;
    }
    return false;
  }

  // for requesting secure data using json
  authJsonHeaders() {
    let token = this.accessToken ? this.accessToken.access_token : "";

    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    return options;
  }

  // for requesting secure data from a form post
  authFormHeaders() {
    let token = this.accessToken ? this.accessToken.access_token : "";
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    return options;
  }

  errorCheck(error: any) {
    console.error("error");
    console.error("status: " + error.status);
    console.error(error);
    if (error.status == 401) {
      this.router.navigateByUrl("/auth/login");
    }
    return  throwError(() => error.json().message || "Server error");
  }
}
