import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

    constructor(
        @Inject('API_BASE_URL') private baseUrl: string) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const url = request.url.startsWith('/') ? `${this.baseUrl}${request.url}` : `${this.baseUrl}/${request.url}`;
        const apiReq = request.clone({ url: url });
        return next.handle(apiReq);
    }
}
