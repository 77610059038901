<div class="login-container text-end">
  <form
    class="k-form login-form"
    name="loginForm"
    [formGroup]="loginForm"
    novalidate
    (ngSubmit)="onSubmit()"
  >
    <div class="row">
      <div class="col-md-12">
        <kendo-formfield>
          <kendo-floatinglabel text="Email">
            <input
              kendoTextBox
              class="w-100 app-box-element login-input"
              formControlName="email"
            />
          </kendo-floatinglabel>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <kendo-formfield>
          <kendo-floatinglabel text="Password">
            <input
              kendoTextBox
              class="w-100 app-box-element login-input"
              formControlName="password"
              type="password"
            />
          </kendo-floatinglabel>
        </kendo-formfield>
      </div>
    </div>
    <div *ngIf="showError" class="text-danger mt-3">
      Invalid email or password.
    </div>

    <button
      kendoButton
      [icon]="'login'"
      type="submit"
      [disabled]="loginForm.invalid"
      [primary]="true"
      class="gradient-btn mt-4"
    >
      <b>LOGIN</b>
    </button>
  </form>
</div>
