import { Subscription } from "rxjs";
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import {
  faCubes,
  faDoorOpen,
  faIndustry,
  faTasks,
  faTruck,
  faUserCog,
  faBraille
} from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "src/app/services/auth.service";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"], 
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, OnDestroy {
  public currentUrl = "";
  public expanded = false;
  public faUsersCog = faUserCog;

  public items: Array<any> = [
    {
      text: "Projects",
      path: "projects",
      icon: faTasks,
    },
    {
      text: "Products",
      path: "products",
      icon: faCubes,
    },
    {
      text: "Suppliers",
      path: "suppliers",
      icon: faTruck,
    },
    {
      text: "Manufacturers",
      path: "manufacturers",
      icon: faIndustry,
    },
    {
      text: "Accessories",
      path: "accessories",
      icon: faBraille,
    }
  ];

  public faDoorOpen = faDoorOpen;
  private subscription!: Subscription;

  constructor(public router: Router, public authService: AuthService) {
    this.currentUrl = router.url;
  }

  ngOnInit(): void {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((val) => {
        this.currentUrl = (val as NavigationEnd).urlAfterRedirects;
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public toggle(): void {
    this.expanded = !this.expanded;
  }

  public onMenuClick(): void {
    if (this.expanded) {
      this.toggle();
    }
  }

  public checkIsSelected(path: string): boolean {
    const urlParts = this.currentUrl.split("/");
    //urlParts.shift();

    if (urlParts.length > 0) return urlParts.includes(path);

    return false;
  }

  public logout(): void {
    this.authService.logout().subscribe((r) => {
      this.router.navigate(["/auth/login"]);
    });
  }
}
