<nav class="sidebar" [ngClass]="{ expanded: expanded }">
  <div class="humb-wrapper">
    <button
      kendoButton
      icon="menu"
      look="flat"
      class="btn btn-link humb-btn"
      (click)="toggle()"
    ></button>
  </div>
  <ng-container *ngFor="let item of items">
    <div
      class="sidebar-item"
      [routerLink]="item.path"
      [ngClass]="{ active: checkIsSelected(item.path) }"
      (click)="onMenuClick()"
    >
      <fa-icon class="item-img" [icon]="item.icon"></fa-icon>
      <div class="item-descr-wrap" *ngIf="expanded">
        <div>{{ item.text }}</div>
      </div>
    </div>
  </ng-container>
  <div
    class="sidebar-item"
    [routerLink]="'admin'"
    [ngClass]="{ active: checkIsSelected('admin') }"
    (click)="onMenuClick()"
    *appHasRole="'Open Admin Pages'"
  >
    <fa-icon class="item-img" [icon]="faUsersCog"></fa-icon>
    <div class="item-descr-wrap" *ngIf="expanded">
      <div>Admin panel</div>
    </div>
  </div>
  <div class="sidebar-item logout-item" (click)="logout()">
    <fa-icon class="item-img" [icon]="faDoorOpen"></fa-icon>
    <div class="item-descr-wrap" *ngIf="expanded">
      <div>Logout</div>
    </div>
  </div>
  <div class="version" *ngIf="expanded">Version 1.1</div>
</nav>
