<kendo-dialog
  title="Action required"
  *ngIf="isOpened"
  [width]="450"
  (close)="onDecline()"
>
  <p style="margin: 30px; text-align: center">{{ message }}</p>

  <kendo-dialog-actions>
    <button kendoButton class="app-box-shadow" (click)="onDecline()">
      <b>No </b>
    </button>
    <button
      kendoButton
      [primary]="true"
      class="app-box-shadow gradient-btn"
      (click)="onAccept()"
    >
      <b>Yes </b>
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
