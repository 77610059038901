import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
//import { LocalStorageService } from "angular-2-local-storage";
import { AuthService } from "src/app/services/auth.service";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  logoURL!: string;
  showError: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    public router: Router,
   // private localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });

    if (this.authService.isLoginError) {
      this.showError = true;
      this.loginForm.setValue({
        email: this.loginForm.get("email")?.value,
        password: "",
      });
    }
    if (this.authService.isLoggedIn) {
      this.navigateCurrentUser();
    }
  }

  navigateCurrentUser(): void {
    this.router.navigate(["/"]);
  }

  onSubmit(): void {
    this.showError = false;
    //this.spinner = true;
    this.authService
      .login(
        this.loginForm.get("email")?.value,
        this.loginForm.get("password")?.value
      )
      .subscribe(
        (r) => {
          if (r) {
            const localStorage = this.document.defaultView?.localStorage;
            localStorage?.setItem(
              "email",
              this.loginForm.get("email")?.value
            );
            this.navigateCurrentUser();
          } else {
            this.showError = true;
            this.loginForm.setValue({
              email: this.loginForm.get("email")?.value,
              password: "",
            });
          }
        },
        (error: any) => {
          console.error(error);
          //TODO: display error message --
          this.showError = true;
          this.loginForm.setValue({
            email: this.loginForm.get("email")?.value,
            password: "",
          });
          //this.spinner = false;
        }
      );
  }
}
