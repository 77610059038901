import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ErrorMessageService } from '../services/error-message.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private errorMessageService: ErrorMessageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // console.error("Error from error interceptor", error);
        this.handleError(error);
        // show dialog for error message
        // this.errorMessageService.errorMessage = JSON.stringify(error);
        return throwError(error);
      }),
      finalize(() => {})
    ) as Observable<HttpEvent<any>>;
  }

  private handleError(err: HttpErrorResponse): any {
    // net core return blob
    if ('application/json' === err.error.type) {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e: any) => {
        const error = JSON.parse(e.target.result)?.error;
        this.errorMessageService.errorMessage = error;
      });
      reader.readAsText(err.error);
    } else {
      this.errorMessageService.errorMessage = 'Application Error occurred';
    }
  }
}
