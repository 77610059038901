import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  authService: AuthService;
  constructor(authService: AuthService) {
    this.authService = authService;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authReq = req.clone({
      headers: req.headers.set(
        "Authorization",
        "Bearer " + this.authService.accessToken.access_token
      ),
    });

    return next.handle(authReq).pipe(
      tap(
        (event) => {},
        (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              console.log("Unauthorized");
            }
          }
        }
      )
    );
  }
}
