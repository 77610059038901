import { Observable, Subscription } from "rxjs";
import { ConfirmService } from "src/app/services/confirm.service";
import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
})
export class ConfirmComponent implements OnInit, OnDestroy {
  public result!: Observable<boolean>;
  public isOpened: boolean = false;
  public subscription: Subscription = new Subscription();
  public message: string = "Are you sure?";

  constructor(private confirmService: ConfirmService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.confirmService.openDialog.subscribe((message) => {
        if (message) {
          this.message = message;
        }
        this.changeDialogStatus();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private changeDialogStatus(): void {
    this.isOpened = !this.isOpened;
  }

  public onAccept(): void {
    this.confirmService.setIsConfirmed(true);
    this.changeDialogStatus();
  }

  public onDecline(): void {
    this.confirmService.setIsConfirmed(false);
    this.isOpened = false;
    //this.changeDialogStatus();
  }
}
