import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ErrorMessageService {
  private _errorMessage: string | null = null;
  private messageDuration = 5000;
  private timer?: ReturnType<typeof setTimeout>;

  get errorMessage(): string | null {
    return this._errorMessage;
  }

  set errorMessage(value: string | null) {
    this._errorMessage = value;
    
    if (value) {
      //this.startClearMessageTimer();
    }    
  }

  constructor() {}

  public clearError(): void {
    this.errorMessage = null;
  }

  private startClearMessageTimer() {
    if (this.timer) {
      clearTimeout(this.timer);      
    }

    this.timer = setTimeout(() => {
      this.errorMessage = null;
    }, this.messageDuration);
  }
}
