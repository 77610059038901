import { Component } from "@angular/core";
import { LoaderService } from "./services/loader.service";
import { Title } from "@angular/platform-browser";
import { AuthService } from "./services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public loadingActive!: boolean;
  public loadingInnerActive!: boolean;
  public isDisplayNavigation = false;

  constructor(
    private loaderService: LoaderService,
    private titleService: Title,
    private authService: AuthService
  ) {
    authService.user$.subscribe((user) => {
      if (user && user.access_token && authService.isLoggedIn) {
        this.isDisplayNavigation = true;
      } else {
        this.isDisplayNavigation = false;
      }
    });

    this.loaderService.loadingActive$.subscribe(
      (active) => (this.loadingActive = active)
    );
    this.loaderService.loadingInnerActive$.subscribe(
      (active) => (this.loadingInnerActive = active)
    );
    this.titleService.setTitle("Mint");
  }
}
