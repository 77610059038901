import { Component } from "@angular/core";
import { ErrorMessageService } from "src/app/services/error-message.service";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-error-message",
  templateUrl: "./error-message.component.html",
  styleUrls: ["./error-message.component.scss"],
})
export class ErrorMessageComponent {
  constructor(public errorMessageService: ErrorMessageService) {}
  faExclamationCircle = faExclamationCircle;

  public clearError(): void {
    this.errorMessageService.clearError();
  }
}
