<body>
  <app-header></app-header>
  <div class="navigation" *ngIf="isDisplayNavigation">
    <app-sidebar></app-sidebar>
  </div>
  <div id="main" class="content-wrapper">
    <app-error-message></app-error-message>
    <ng-container *ngIf="loadingInnerActive">
      <div class="k-i-loading"></div>
    </ng-container>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <app-confirm></app-confirm>
  </div>
  <div class="spinner-wrapper" *ngIf="loadingActive">
    <div class="k-i-loading"></div>
  </div>
</body>
